// WRITE ARTICLE
export const addAPayoutAccount = {
  order: 5,
  name: "Manage payout accounts",
  keywords: "earn bank debit add team shared default",
  subcategory: "Manage your account",
  markdown: `# Manage payout accounts

  {% callout type="check" marginStyles="mb-4" %}
  Tower Hunt partners with [Stripe](https://stripe.com) to manage payments and payouts.
  {% /callout %}

  ## Personal payout account

  An easy starting point for payouts is using a personal bank account or debit card:

  ### Add an account

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Earning on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Setup Personal Payouts**, click **Let's go**.
  5. Input your account information via [Stripe's](https://stripe.com) dashboard, which opens in a new tab.
  {% /tablessContainer %}

  ### Manage an account

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Earning on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Personal Payouts Dashboard**, click **Payout settings**.
  5. Add, edit, and remove payout information via [Stripe's](https://stripe.com) dashboard, which opens in a new tab.
  {% /tablessContainer %}

  ## Shared payout account

  Tower Hunt also allows users to connect property-, team-, or company-level bank accounts or debit cards. This is useful in a variety of situations:

  - A property can recognize revenue generated from its own data.
  - A real-world team wants to share revenue generated from data that a single team member enters on the team's behalf.
  - A company wants to capture revenue entered by many employees across many Tower Hunt user accounts. This is especially useful when the {% inlineRouterLink articleId="connect-a-shared-payout-account-to-a-team" %}shared account is connected to a team{% /inlineRouterLink %}.

  ### Add an account

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Earning on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Shared Payout Accounts**, click **Setup new account**.
  5. (Optional) edit shared account name.
  ![Edit shared payout account name](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  6. (Optional) edit shared account type.
  ![Edit shared payout account type](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  7. Click **Setup** to input your account information via [Stripe's](https://stripe.com) dashboard, which opens in a new tab.
  {% /tablessContainer %}

  ## Set a default payout account

  Since a user can potentially contribute hundreds or even thousands of datapoints, it is important to have a default payout account that will receive earnings in most cases.

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click your initials in the upper-right and choose **My Account** from the menu.
  3. Under **Earning on Tower Hunt**, enter your password to verify that it's you and click **Submit**.
  4. Under **Default Payout Account**, select the desired payout account from the dropdown.
  ![Default payout account](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}

  ## Datapoint earnings override

  When it is desirable for the revenue from a specific datapoint to be captured by a *non-default* payout account, you can use the {% inlineRouterLink %}Contributions tab{% /inlineRouterLink %} to set an override:

  {% tablessContainer %}
  1. {% inlineRouterLink articleId="sign-in-to-tower-hunt" %}Sign in to Tower Hunt{% /inlineRouterLink %}.
  2. Click the **Me** tab.
  3. Click the {% inlineAppIcon iconName="dollar" /%}**Contributions** section within the **Me** tab.
  4. Under **Contribution Details**, click **Earnings**.
  5. Locate a row that contains a paid datapoint. Under the **Earnings** column, select the desired payout account from the dropdown.
  ![Datapoint payout override](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
  {% /tablessContainer %}`,
};
